import Parse from 'parse';
Parse.serverURL = 'https://rupiah.b4a.io';
Parse.initialize(
    'Kjqo1GEmwkKzSvvKXPDgXlVRPIgejm09qJlcpxPz', // This is your Application ID
    'o6xWxH0SKVcWh7gEw66WGAz8rDt7bSPcaZusps3p' // This is your Javascript key
  );
//javascriptKey is required only if you have it on server.



export default Parse